import { useState } from 'react'
import { supabase } from '../supabaseClient'
import { Link } from "react-router-dom"
import './Styles/Auth.css'
import Logo from './Images/outcomeLogo.svg'

export default function Auth() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleLogin = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithOtp({ email })
      if (error) throw error
      // alert('Check your email for the login link!')
      setSuccess(true)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="background">
      <div className="auth" aria-live="polite">
        <img src={Logo}/>
        <h1>Get Started</h1>
        <p>Sign up with your work email and we’ll send you a link to get started.</p>
        
        {success ? (
          <div className='success'>Check your email for the sign up link!</div>
        ) : (
          <div></div>
        )}

        {error ? (
          <div className='error'>Something went wrong, please try again.</div>
        ) : (
          <div></div>
        )}
        
        {loading ? (
          <div className='loader'></div>
        ) : (
          <form onSubmit={handleLogin}>
            <input
              id="email"
              type="email"
              placeholder="Work Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="button block" aria-live="polite">
              Get Started
            </button>
          </form>
        )}
          <p>Already have an account? <Link to="/login">Log In</Link></p>
      </div>
      <span className='disclaim'>© Try Outcome 2022</span>
    </div>
  )
}