import { useState, useEffect } from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { supabase } from '../supabaseClient'
import './Styles/Auth.css'
import './Styles/Navigation.css'
import Logo from './Images/outcomeLogo.svg'

const Account = ({ session }) => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(null)
  const [title, setTitle] = useState(null)
  const [company, setCompany] = useState(null)
  const [research_participant, setResearchParticipant] = useState(false)
  const [avatar_url, setAvatarUrl] = useState(null)

  useEffect(() => {
    getProfile()
  }, [session])

  const getProfile = async () => {
    try {
      setLoading(true)
      const { user } = session

      let { data, error, status } = await supabase
        .from('profiles')
        .select(`username, title, avatar_url, company, research_participant`)
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setUsername(data.username)
        setTitle(data.title)
        setCompany(data.company)
        setAvatarUrl(data.avatar_url)
        setResearchParticipant(data.research_participant)
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateProfile = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { user } = session

      const updates = {
        id: user.id,
        username,
        title,
        company,
        avatar_url,
        research_participant,
        updated_at: new Date(),
      }

      let { error } = await supabase.from('profiles').upsert(updates)

      if (error) {
        throw error
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='background'>
        <div className='navigation'>
          <div className='logo'>
            <img src={Logo}/>
          </div>
          <Menu menuButton={
            <div className='avatarBorder'>
              <div className='avatar'>{session.user.email}</div>
            </div>
          } transition>
            <MenuItem onClick={() => supabase.auth.signOut()}>Sign Out</MenuItem>
          </Menu>
        </div>
        <div className="auth margin-top-large smaller-top-padding" aria-live="polite">
        {loading ? (
          <div className='loader'></div>
        ) : (
          <form onSubmit={updateProfile} className="form-widget">
             <div className='message'>While we're in beta, we're sending out invites periodically. Filling out your info will bump you up on the list and we'll reach out shortly with an invite!</div>
            <div>
              <label htmlFor="username">Name</label>
              <input
                id="username"
                className='no-margin'
                type="text"
                value={username || ''}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              
              <label htmlFor="title">Title</label>
              <input
                id="title"
                type="text"
                className='no-margin'
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="company">Company URL</label>
              <input
                id="company"
                type="text"
                className='no-margin'
                value={company || ''}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
          <div>
          </div>
            <div>
              <button className="button primary block margin-top-small" disabled={loading}>
                Update profile
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
    
  )
}

export default Account