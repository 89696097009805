import './index.css'
import React from "react"
import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import SignUp from './Pages/SignUp'
import LogIn from './Pages/LogIn'
import Account from './Pages/Account'
import { Route, Routes, Navigate } from "react-router-dom"




export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<SignUpPage />} />
        <Route path="/login" element={<LogInPage />} />
      </Routes>
    </div>
  )
}

function SignUpPage() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
    <div>
      {/* {!session ? <SignUp /> : <Account key={session.user.id} session={session} />} */}
      {!session ? <SignUp /> : <Account key={session.user.id} session={session} />}
    </div>
  )
}



function LogInPage() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
    <div>
      <LogIn />
      {!session ? <LogIn /> : <SignUpPage />}
    </div>
  )
}

// function HomePage() {
  
//   return (
//     <div>hi</div>
//   )
// }
